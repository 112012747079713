:root {
	--ck-z-default: 10000000;
	--ck-z-panel: calc(var(--ck-z-default) + 999);

	--link-color: var(--rs-amber-700);
	--link-text-shadow: 0px 0px 3px var(--rs-graphite-50);
	--link-font-weight: 400;

	--rs-amber-50: #fff9f2;
	--rs-amber-100: #ffe8cc;
	--rs-amber-200: #ffd7a3;
	--rs-amber-300: #ffc87a;
	--rs-amber-400: #ffba52;
	--rs-amber-500: #ffad29;
	--rs-amber-600: #f5a61d;
	--rs-amber-700: #e09812;
	--rs-amber-800: #c28408;
	--rs-amber-900: #996900;

	--rs-sapphire-50: #f2f8ff;
	--rs-sapphire-100: #d5e5f7;
	--rs-sapphire-200: #b4cff0;
	--rs-sapphire-300: #96bbeb;
	--rs-sapphire-400: #78a5e3;
	--rs-sapphire-500: #5c8edb;
	--rs-sapphire-600: #427ad4;
	--rs-sapphire-700: #2761c4;
	--rs-sapphire-800: #1047ad;
	--rs-sapphire-900: #00308f;

	--rs-emerald-50: #f2fff7;
	--rs-emerald-100: #d2f7e2;
	--rs-emerald-200: #aff0cb;
	--rs-emerald-300: #8ce6b4;
	--rs-emerald-400: #6ddea2;
	--rs-emerald-500: #4fd690;
	--rs-emerald-600: #3acf84;
	--rs-emerald-700: #24bf74;
	--rs-emerald-800: #11a862;
	--rs-emerald-900: #018a4c;

	--rs-graphite-50: #f5f5f5;
	--rs-graphite-100: #cbcccc;
	--rs-graphite-200: #a2a3a4;
	--rs-graphite-300: #787a7b;
	--rs-graphite-400: #4f5153;
	--rs-graphite-500: #25282a;
	--rs-graphite-600: #1c1e20;
	--rs-graphite-700: #131415;
	--rs-graphite-800: #090a0a;
	--rs-graphite-900: #000000;

	--rs-text-primary: var(--rs-graphite-500);
	--rs-border-primary: var(--rs-graphite-200);
	--rs-btn-primary-bg: var(--rs-amber-300);
	--rs-btn-primary-hover-bg: var(--rs-amber-400);
	--rs-btn-primary-active-bg: var(--rs-amber-400);
	--rs-btn-primary-text: var(--rs-graphite-500);
	--rs-navs-tab-border: var(--rs-border-primary);
	--rs-divider-border: var(--rs-border-primary);
}

body {
	margin-top: 60px !important;
	color: #25282a;
	background: #f5f5f5 none;
	font-family: Navigo, Arial, Helvetica, sans-serif;
	font-weight: 300;
	font-size: 16px;
	letter-spacing: 0.3px;
}

a {
	color: #25282a;
}

a[disabled] {
	pointer-events: none;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	letter-spacing: 0;
}

h1 {
	margin-bottom: 1em;
}

.sticky-top {
	top: 90px;
}

.dropdown-item {
	font-weight: 300;
}

#root {
	max-width: 100vw;
}

/*
 * Constant-Aspect Ratio Responsive Box
 *
 * Maintains aspect ratio while resizing to parent container width.
 * Chrome, FireFox, IE8+
 *
 * Example:
 * <div class="box box-4x3">
 *    <div class="content"> ... </div>
 * </div>
 *
 */
.box {
	position: relative;
	width: 100%;
	overflow: hidden;
}

.box::before {
	content: '';
	display: block;
	padding-top: 100%;
}

.box > .content {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
}

.box-1x2::before {
	padding-top: 200%;
}

.box-2x3::before {
	padding-top: 150%;
}

.box-1x1::before {
	padding-top: 100%;
}

.box-4x3::before {
	padding-top: 75%;
}

.box-3x2::before {
	padding-top: 66.66666666%;
}

.box-16x9::before {
	padding-top: 56.25%;
}

.box-2x1::before {
	padding-top: 50%;
}

.box-21x9::before {
	padding-top: 42.857142857143%;
}

.box-18x5::before {
	padding-top: 27.777%;
}
/* END */

.page-title {
	position: fixed;
	z-index: 100000;
	width: calc(100vw - 240px);
	background-color: var(--rs-amber-400);
	color: var(--rs-graphite-500);
	text-align: center;
	padding: 5px 15px;
	font-size: 20px;
	line-height: 1.1;
	font-weight: 700;
	text-transform: uppercase;
}

table {
	background-color: #fff;
}

.rs-tabs {
	gap: 0;
}
.rs-tabs-content {
	padding: 15px;
	border-width: 0 1px 1px;
	border-style: solid;
	border-color: var(--rs-border-primary);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
	background-color: #fff;
}
.rs-stack {
	width: 100%;
}
.rs-text {
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
}
.rs-content.content-frame {
	max-width: 100vw;
	padding-left: 240px;
}
.rs-content.content-frame > .rs-flex-box-grid {
	margin-top: 32px;
}

.rs-panel {
	background-color: #fff;
	margin: 30px 0;
	overflow: visible;
}
.rs-panel.rs-panel-bordered {
	border-color: var(--rs-border-primary);
}

.rs-panel-body > .rs-grid-container-fluid {
	margin: 0;
	width: 100%;
}

.rs-container {
	position: relative;
}
.rs-content > .rs-container {
	padding: 15px;
}
.rs-container.has-subnav {
	padding: 60px 15px 15px;
}
.rs-navbar.subnav {
	position: fixed;
	top: 60px;
	left: 240px;
	right: 0;
	width: auto;
	z-index: 1;
}
.rs-navbar.subnav .subnav-toggle {
	padding: 15px;
}
.rs-navbar.subnav .subnav-toggle label {
	margin-right: 1em;
}

.rs-drawer-wrapper {
	z-index: 100001;
}
.rs-modal-backdrop {
	z-index: 100002;
}
.rs-modal-wrapper {
	z-index: 100003;
}

.rs-btn-toolbar > button > svg {
	margin-top: -10px;
	width: 30px;
	height: 30px;
}
.utility-btn > svg {
	margin-top: -3px;
	width: 30px;
	height: 30px;
}
.rs-sidenav-item > svg.rs-sidenav-item-icon {
	margin-top: -5px;
}
label > svg {
	margin-top: -3px;
}

.divider > hr {
	margin: 30px 0 10px;
}

.rs-input.table-cell-editable {
	margin-top: -8px;
}

.rs-modal {
	margin: 90px auto 0;
}
.rs-modal-full.modal-full {
	margin: 0;
	height: 100vh;
}
.modal-full .rs-modal-dialog {
	width: 90vw !important;
	max-width: 90vw !important;
}

.modal-full .rs-container,
.modal-full .rs-row,
.modal-full .rs-col {
	height: 100%;
}

.modal-full .rs-modal-content {
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100vh !important;
	max-height: 100vh !important;
	padding: 0;
}

.rs-modal-header,
.rs-modal-footer {
	padding: 35px;
}
.rs-modal-header {
	min-height: 30px;
}
.rs-modal-header .rs-modal-header-close {
	font-size: 30px;
}

.rs-modal-body {
	flex-grow: 1;
	overflow-y: auto;
	margin: 0;
	padding: 20px;
}

.rs-dropdown-item-with-icon > svg {
	margin-right: 10px;
}

.form-grid .row {
	margin: 0 0 30px;
}

.input-group .form-floating {
	flex-grow: 1;
}

.input-group .form-floating input {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}

.nav-tabs .nav-item .nav-link {
	color: inherit;
	font-weight: 400;
}

.tab-content .tab-pane {
	background-color: #fff;
	padding: 15px;
	border-width: 0 1px 1px;
	border-style: solid;
	border-radius: 0 0 6px 6px;
	border-color: var(--rs-border-primary);
	margin-bottom: 15px;
}

.rs-navbar-item-disabled {
	pointer-events: none;
	display: none;
}
.rs-sidenav-item > svg {
	width: auto;
	margin-right: 10px;
}

.rs-sidenav-collapse-in .rs-dropdown-item,
.rs-sidenav-collapse-in .rs-dropdown-item-submenu > .rs-dropdown-item-toggle {
	padding: 11px 20px 11px 46px;
}

.app-link,
.app-link:hover,
.app-link:active {
	text-decoration: none;
}

.page-center {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	display: flex;
	align-items: center;
	justify-content: center;
}

.dropdown-header {
	text-transform: uppercase;
	font-weight: 400;
}

.rs-navbar-default {
	background-color: #fff;
}

.rs-sidebar {
	position: fixed;
	left: 0;
	top: 60px;
	bottom: 0;
	border-right: 1px solid var(--rs-border-primary);
}

.rs-sidenav-nav > .rs-dropdown .rs-dropdown-toggle,
.rs-sidenav-nav > .rs-sidenav-item {
	padding-left: 30px !important;
}

.rs-sidenav-item-divider {
	margin: 0;
	border-top: 1px solid var(--rs-border-primary);
}
.rs-sidenav-default,
.rs-sidenav-default .rs-dropdown-toggle,
.rs-sidenav-default .rs-sidenav-item,
.rs-sidenav-default .rs-sidenav-toggle-button {
	background-color: #fff;
	color: #25282a;
	height: 100%;
	overflow: auto;
}
.rs-sidenav-default .rs-sidenav-item-active,
.rs-sidenav-default .rs-dropdown-item-active {
	background-color: #f2f2f2;
}

/* .rs-sidenav a:hover {
	background-color: #FFAC2A !important;
}
 */
/* .rs-content {
	padding: 15px;
} */

.rs-picker-popup {
	z-index: 10000;
}

.disable-overlay {
	content: '';
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background-color: rgba(255, 255, 255, 0.5);
	pointer-events: none;
}

.image-gallery {
	background-color: #fff;
	border-bottom: 1px solid var(--rs-border-primary);
	padding: 15px;
}
.image-gallery.single {
	background-color: #fff;
}
.image-gallery.single .box::before {
	padding-top: 0;
}
.image-gallery.single .box > .content {
	position: static;
}
.image-gallery img {
	object-fit: contain;
	width: 100%;
	height: 100%;
}
.swiper-button-next,
.swiper-button-prev {
	background-color: #1b1d20;
	outline: rgba(255, 255, 255, 0.5) solid 2px;
	font-weight: 900;
	width: 50px;
	height: 50px;
	top: 50%;
	border-radius: 25px;
}
.swiper-button-next svg *,
.swiper-button-prev svg * {
	fill: #fff;
	width: auto;
	height: 24px;
}
.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
	display: none;
}
.swiper-pagination-bullet {
	width: 10px;
	height: 10px;
	background-color: #1b1d20;
	border: 2px solid #1b1d20;
	outline: rgba(255, 255, 255, 0.5) solid 2px;
	opacity: 1;
}
.swiper-pagination-bullet-active {
	background-color: #fff;
}

.inline-editable {
	width: 100%;
}
.inline-editable .rs-plaintext {
	width: fit-content;
}
.rs-inline-edit .rs-plaintext,
.rs-inline-edit .rs-input {
	font-size: inherit;
	line-height: inherit;
}
.rs-inline-edit .rs-inline-edit-controls {
	font-size: 14px;
	line-height: 1em;
}
.rs-inline-edit .rs-inline-edit-controls button {
	background: #fff;
	border: 1px solid #25282a;
}
.edit-group {
	display: flex;
	align-items: center;
}
.edit-group > label {
	line-height: inherit;
	padding-right: 1em;
}

.scroll-end {
	text-align: center;
	font-weight: 400;
}

.infinite-scroll-component {
	overflow-x: visible !important;
	overflow-y: clip !important;
}
[class*='video-module_videoOverlay__'] {
	background-color: transparent !important;
}

.ck-rounded-corners .ck.ck-balloon-panel,
.ck.ck-balloon-panel.ck-rounded-corners {
	z-index: 1000000;
}

.ck-content a,
.post-card .card-text a,
.rs-panel a {
	color: var(--link-color);
	text-shadow: var(--link-text-shadow);
	font-weight: var(--link-font-weight);
}
.step-content a {
	display: inline-block;
	padding: 0 5px;
	background-color: var(--rs-btn-primary-bg);
	color: var(--rs-btn-primary-text);
	font-weight: var(--link-font-weight);
	border-radius: 4px;
}

.emerge-links {
	position: relative;
	top: 100px;
}
.emerge-links .emerge-links-label {
	position: absolute;
	font-size: 14px;
	font-weight: bold;
	top: 100%;
	left: 15px;
	padding: 5px 15px;
	background-color: #fff;
	border-width: 0px 1px 1px;
	border-style: solid;
	border-color: var(--rs-border-primary);
	border-bottom-left-radius: 6px;
	border-bottom-right-radius: 6px;
}
.emerge-links .emerge-links-label:hover {
	cursor: pointer;
}

.update-notification {
	position: fixed;
	bottom: 15px;
	left: 15px;
	z-index: 10;
}

@media screen and (max-width: 991px) {
	body {
		padding-bottom: 60px;
	}
	h1 {
		font-size: 24px;
		line-height: 1.3;
	}
	h2 {
		font-size: 20px;
		line-height: 1.3;
	}
	h3 {
		font-size: 18px;
		line-height: 1.3;
	}

	.rs-content.content-frame {
		padding-left: 15px;
		padding-right: 15px;
	}

	.rs-drawer {
		max-width: 100vw;
	}

	.page-title {
		margin: 0 -15px;
	}
}

@media screen and (max-width: 479px) {
	.update-notification {
		bottom: 85px;
		width: calc(100vw - 30px);
	}
}
